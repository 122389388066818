import React, { Fragment, useState } from "react";
import "./DashboardAccount.scss";
import PopUpModal from "../../PopUpModal/PopUpModalComponent";
import ContactModal from "../../ContactModal/ContactModalComponent";

const DashboardAccount = ({ component, hideHeader }) => {
  const [open, setOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const { widgets = [], title, showRachat } = component;

  const getWidgets = () =>
    widgets.map(
      ({ title, subTitle, type, firstLine, secondLine, recaps }, i) => {
        const isSegmented = firstLine !== "Toute la base mList";
        switch (type) {
          case "SEGMENTATION":
            return (
              <Fragment key={i}>
                <div className='box-entry'>
                  <div className='box-title'>{title}</div>
                  <div className='box-content'>
                    <div>
                      <p>{firstLine}</p>
                    </div>
                    {isSegmented && (
                      <div className='link-wrapper'>
                        <span className='icon icon-propos' />
                        <button
                          type='button'
                          className='fake-link link-entry'
                          onClick={() => setOpen(true)}
                        >
                          En savoir plus
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {open && (
                  <PopUpModal
                    open={open}
                    closeModal={() => setOpen(false)}
                    title={title}
                    description='Retrouvez ici le contenu de la segmentation qui vous est affectée.'
                    content={
                      !!recaps?.recapFilterFamilies?.length && (
                        <>
                          <h3 className='segmentation-title'>{firstLine}</h3>
                          <ul className='primary-list'>
                            {recaps.recapFilterFamilies.map(
                              ({ labelFamily, recapFilterData }) => (
                                <li key={labelFamily}>
                                  <span className='list-heading'>
                                    {labelFamily}
                                  </span>
                                  {!!recapFilterData?.length && (
                                    <ul className='under-list'>
                                      {recapFilterData.map(
                                        ({ label, exclu }) => (
                                          <li key={label}>
                                            <span
                                              className={
                                                "icon icon-" +
                                                (!!exclu ? "close" : "confirm")
                                              }
                                            />
                                            <span className='title'>
                                              {label}
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        </>
                      )
                    }
                  />
                )}
              </Fragment>
            );
          case "CREDIT":
            return (
              <div key={i} className='box-entry'>
                <div className='box-title'>{title}</div>
                <div className='box-title'>{subTitle}</div>
                <div className='box-content'>
                  <div>
                    <span>{firstLine}</span>
                  </div>
                  <div>{secondLine}</div>
                </div>
              </div>
            );
          default:
            return null;
        }
      }
    );

  const toggleContactModal = () => setContactModalOpen(!contactModalOpen);

  return (
    <div className='dashboard-account-component'>
      {!hideHeader && (
        <header className='header-section'>
          <h2>Bienvenue sur mList</h2>
          <h3>
            Depuis votre Dashboard, consultez votre solde de crédits, visualisez
            vos derniers ciblages, et vos listes créées.
          </h3>
        </header>
      )}

      <div className='my-account'>
        <div className='my-account__label'>{title}</div>
        <div className='my-account__box-list'>{getWidgets()}</div>
      </div>
      {showRachat && (
        <>
          <div className='dashboard-cta-container'>
            <button
              className={"btn btn-secondary"}
              onClick={toggleContactModal}
            >
              Racheter des crédits
            </button>
          </div>
          <ContactModal
            open={contactModalOpen}
            onClose={toggleContactModal}
            refCampagne='web_mlist_dde_credit'
          />
        </>
      )}
    </div>
  );
};

export default DashboardAccount;
