import React, { useState } from "react";

import { Col, Row } from "react-flexbox-grid";

import ContactModal from "../ContactModal/ContactModalComponent";

export default function AdminSettings({ component }) {
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const toggleContactModal = () => {
    setContactModalOpen(!contactModalOpen);
  };

  return (
    <div className='admin-settings'>
      <div className='page-heading'>
        <Row className='ovv'>
          <Col sm={12} md={9}>
            <h2>{component.title}</h2>
          </Col>
        </Row>
        <Row className='settings-info'>
          <Col sm={12} md={9}>
            {component.accountIdLabel}
            <b>{component.accountIdFisrtPart}</b>
            {component.accountIdLastPart}
          </Col>
        </Row>
        <Row className='settings-info'>
          <Col sm={12} md={9}>
            {component.accountNameLabel}
            <b>{component.accountName}</b>
          </Col>
        </Row>
        <Row className='settings-info-plan'>
          <Col sm={12} md={9}>
            {component.planLabel}
            <b>{component.plan}</b>
          </Col>
        </Row>
        <Row className='settings-info'>
          <Col sm={12} md={9}>
            {component.creationDateLabel}
            <b>{component.creationDate}</b>
          </Col>
        </Row>
        {component.accountSpothitId && (
          <Row className='settings-info-plan'>
            <Col sm={12} md={9}>
              {component.accountSpothitLabel}
              <b>{component.accountSpothitId}</b>
            </Col>
          </Row>
        )}
      </div>
      <div className='table-targeting table-section'>
        <div className='table-holder'>
          <div className='table-wrapper'>
            <div className='colored-heading'>
              <div className='white-borders'>
                <table>
                  <thead>
                    <tr>
                      <th>Fonctionnalités incluses</th>
                      <th>Solde de crédits</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <ul>
                          {component.features.map((element, key) => (
                            <li key={key}>
                              <span className={`icon icon-${element.icon}`} />
                              {element.label}
                              <b>{element.valueBalance}</b>
                              {element.valueCredit}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul>
                          {component.balances.map((element, key) => (
                            <li key={key}>
                              <span className={`icon icon-${element.icon}`} />
                              {element.label}
                              <b>{element.valueBalance}</b>
                              {element.valueCredit}
                            </li>
                          ))}
                        </ul>
                        <>
                          <div className='dashboard-cta-container'>
                            <button
                              className={"btn btn-secondary"}
                              onClick={toggleContactModal}
                            >
                              Racheter des crédits
                            </button>
                          </div>
                          <ContactModal
                            open={contactModalOpen}
                            onClose={toggleContactModal}
                            refCampagne='web_mlist_dde_credit'
                          />
                        </>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='table-holder'></div>
    </div>
  );
}
